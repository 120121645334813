<template>
  <div>
    <Modal modal-class="slide-up" ref="modal_create_entry" size="lg" :on-hide="clearEntryToSave"
           :modal-header-title="$t('hotel.create room type')">
      <template v-slot:container>
        <ValidationObserver ref="form_create_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(saveEntry)">
            <div class="modal-body">
              <AppInput name="name" :label="$t('hotel.room type')" type="string" rules="required"
                        v-model="entryToSave.name"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <Modal modal-class="slide-up" ref="modal_edit_entry" size="lg" :on-hide="clearEntryToEdit"
           :modal-header-title="$t('hotel.update room type')">
      <template v-slot:container>
        <ValidationObserver ref="form_update_entry" v-slot="{ passes, valid, validated }">
          <form novalidate autocomplete="off" @submit.prevent="passes(updateEntry)">
            <div class="modal-body">
              <AppInput name="name" :label="$t('hotel.room type')" type="string" rules="required"
                        v-model="entryToEdit.name"/>
            </div>
            <div class="modal-footer">
              <button type="button" :class="['btn btn-default btn-cons no-margin inline btn-modal-cancel']"
                      data-dismiss="modal">
                {{ $t('common.cancel') }}
              </button>
              <button :disabled="!valid && validated" type="submit"
                      :class="['btn btn-cons inline btn-modal-submit', 'btn-complete']">
                {{ $t('common.save') }}
              </button>
            </div>
          </form>
        </ValidationObserver>
      </template>
    </Modal>
    <BasicTable ref="table"
                :table-name="tableName"
                :settingColumns="columns"
                :actions="actions"
                :settingTools="tools"
                :setting-apis="apis"></BasicTable>
  </div>
</template>
<script>
import BasicTable from '@components/_common/List/BasicTable';

export default {
  data() {
    return {
      tableName: this.$t('hotel.room type list'),
      apis: {
        list: {
          endpoint: this.$consts.API.ADMIN.HOTEL_ROOM_TYPE_LIST,
          params: {"filters[hotel_id][equal]": this.$route.params.id}
        },
        delete: {
          endpoint: this.$consts.API.ADMIN.HOTEL_ROOM_TYPE_DELETE,
        }
      },
      actions: {
        editEntry: this.editEntry,
        createEntry: this.createEntry,
        deleteEntry: true,
      },
      tools: {
        search: {
          filters: []
        }
      },
      entrySelectedDelete: {},
      entryToSave: {},
      entryToEdit: {},
      hotel: {},
      columns: [
        //{name: 'id', label: 'ID', fitSize: true},
        {name: 'name', label: this.$t('hotel.room type'), detailHeader: true},
        {name: 'creator', label: this.$t('common.creator'), listShow: false},
        {name: 'created_at', label: this.$t('common.created at'), listShow: false},
        {name: 'updater', label: this.$t('common.updater'), listShow: false},
        {name: 'updated_at', label: this.$t('common.updated at'), listShow: false},
      ]
    }
  },
  components: {BasicTable},
  mounted() {
    this.$request.get(this.$consts.API.ADMIN.HOTEL_DETAIL(this.$route.params.id), null, {
      noLoadingEffect: true,
      handleNotFound: true
    }).then(res => {
      if (!res.hasErrors()) {
        this.hotel = res.data.data
        this.$store.commit('setting/updateBreadCrumb', {hotel: this.hotel.name})
      }
    })
  },
  methods: {
    /* CREATE entry start */
    clearEntryToSave() {
      this.entryToSave = {}
      this.$refs.form_create_entry.reset()
    },
    createEntry() {
      this.$refs.modal_create_entry.show();
    },
    async saveEntry() {
      const {name} = this.entryToSave
      const res = await this.$request.post(this.$consts.API.ADMIN.HOTEL_ROOM_TYPE_SAVE, {
        name, hotel_id: this.hotel.id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg create ok'))
        this.$refs.modal_create_entry.hide();
        this.entryToSave = {};
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_create_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    /* CREATE entry end */

    /* UPDATE entry start */
    editEntry(entry) {
      this.entryToEdit = _.clone(entry)
      this.$refs.modal_edit_entry.show();
    },

    async updateEntry() {
      const {name} = this.entryToEdit
      const res = await this.$request.patch(this.$consts.API.ADMIN.HOTEL_ROOM_TYPE_EDIT(this.entryToEdit.id), {
        name, hotel_id: this.hotel.id
      })
      if (!res.hasErrors()) {
        this.$appNotice.success(this.$t('common.msg update ok'))
        this.$refs.modal_edit_entry.hide();
        this.$refs.table.getEntries()
      } else {
        if (res.data?.errors) {
          this.$refs.form_update_entry.setErrors(res.data.errors);
        } else {
          this.$appNotice.error(res.data?.error_msg)
        }
      }
    },
    clearEntryToEdit() {
      this.$refs.form_update_entry.reset()
    },
    /* UPDATE entry end */
  }
}
</script>
